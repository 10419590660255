import React, { useState, useEffect } from "react";
import { getProofImage } from "../API/getImage";
import Loading from "../Bocomponents/Loader.jsx";
import States from "../Utilities/state.json";


// import ReactTooltip from "react-tooltip";
import "./view.css";

export const Viewproof = ({
  rowData,
  onManualStatusChange,
  onRejectReasonChange,
  onRejectCommentsChange,
}) => {
  // added for testing
  const [rejectReason, setRejectReason] = useState("");
  const [rejectComments, setRejectComments] = useState("");
  const [approvalStatus, setApprovalStatus] = useState("");
  const [zoomFactor, setZoomFactor] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [transactionImage, setTransactionImage] = useState("");
  const [rotationAngle, setRotationAngle] = useState(0);
  const [accNo, setAccNo] = useState(rowData.accountNo);
  const [ifscCode, setIfscCode] = useState(rowData.ifscCode);
  const [bankName, setBankName] = useState(rowData.bankName);
  const [branchName, setBranchName] = useState(rowData.branchName);
  const [add1, setAdd1] = useState(rowData.address1);
  const [add2, setAdd2] = useState(rowData.address2);
  const [add3, setAdd3] = useState(rowData.address3);
  const [addCity, setAddCity] = useState(rowData.city);
  const [pincode, setPincode] = useState(rowData.pincode);
  const [msgValue, setMsgValue] = useState(rowData.msgId);
  const [state, setState] = useState(rowData.state);
  // const [state, setState] = useState("gujarat");
  /// get image from api
  useEffect(() => {
    const fetchImage = async () => {
      setIsLoading(true); 
      if (rowData.transactionId && rowData.eiaNo) {
        try {
          const image = await getProofImage(
            rowData.transactionId,
            rowData.eiaNo
          );
          setTransactionImage(image);
          // console.log(image, "---------Trasactionimage");
        } catch (error) {
          console.error("Error fetching image:", error);
        } finally {
          setIsLoading(false); 
        }
      }
    };

    fetchImage();
  }, [rowData.transactionId, rowData.eiaNo]);


  ///image buttons
  const handleZoomIn = () => {
    setZoomFactor(zoomFactor + 0.1);
  };
  function toTitleCase(state) {
    return state.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

  const handleZoomOut = () => {
    setZoomFactor(zoomFactor - 0.1);
  };
  const handleZoomReset = () => {
    setZoomFactor(1);
    setRotationAngle(0);
  };
  const handleRotate = () => {
    const newRotationAngle = rotationAngle + 90;
    setRotationAngle(newRotationAngle);
  };

  ///rejection reason and comments
  const handleRejectReasonChange = (event) => {
    const reason = event.target.value;
    setRejectReason(reason);
    onRejectReasonChange(reason);
    // console.log(reason, "========<>rejectReason");
  };
  const handleRejectCommentsChange = (event) => {
    const comments = event.target.value;
    setRejectComments(comments);
    onRejectCommentsChange(comments);
    // console.log(comments, "========<>rejectComments");
    // setDataModel(ModelData)
  };

  ///approval or reject function
  const handleApprovalChange = (event) => {
    setApprovalStatus(event.target.value);
    let manualStatus;
    

    // console.log(event.target.value, add1, "<========radio check");

    if (event.target.value !== "reject") {
      manualStatus = "Approved";
    } else {
      manualStatus = "Rejected";

      // console.log(manualStatus, "============<>manualstatus check");
    }
    const kycProofId=rowData.kycProofId
    const poaCode=rowData.poaCode
    const pobCode = rowData.pobCode
  
    
   
    onManualStatusChange(
      manualStatus,
      add1,
      add2,
      add3,
      addCity,
      pincode,
      state,
      accNo,
      ifscCode,
      bankName,
      branchName,
      msgValue,
      kycProofId,
      poaCode,
      pobCode
    );
  };
  const handleState=(event)=>{
    setState(event.target.value)
    // console.log(state,"<=======state");
  }
  const handleDownload = () => {
    const anchor = document.createElement("a");
    anchor.href = transactionImage;
    anchor.download = `${rowData.eiaNo}.jpeg`;
    anchor.click();
  };

  const transactionType = rowData.serviceType
  // console.log(transactionType,"<--------------transactionType ");
  const status = rowData.manualStatus;

  const inputNumeric = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9]+$/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const inputAlpha = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^A-Z]+$/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const inputAlphaNumericWithSpl = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z-_/\\]+$/gm, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const inputAlphaNumeri = (e, callback) => {
    const value = e.target.value.toUpperCase()
      ? e.target.value.replace(/[^0-9a-zA-Z]+$/gm, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value.toUpperCase());
    }
  };

  return (
    <div className="page-container">
      {isLoading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <header className="header1 color">
        <span>Name:{rowData.customerName}</span>
        <span>D.O.B: {rowData.dob}</span>
        <span>EIA Number: {rowData.eiaNo}</span>
        <span>Transaction ID: {rowData.transactionId}</span>
      </header>
      <div>
        <span>Preview Document(s): {rowData.documentType}</span>
      </div>
      
       
            <div className="zoom-buttons">
              <i
                class="fa-solid fa-magnifying-glass-plus "
                onClick={handleZoomIn}
              ></i>
              <i
                class="fa-solid fa-magnifying-glass-minus"
                onClick={handleZoomOut}
              ></i>
              <i
                class="fa-solid fa-rectangle-xmark"
                onClick={handleZoomReset}
              ></i>
              <i class="fa-solid fa-camera-rotate" onClick={handleRotate}></i>
              
            </div>
         
   
      <div className="cards-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
     <div className="img-card">
  <div>
    {isLoading ? (
      <span>Loading...</span>
    ) : transactionImage && Array.isArray(transactionImage) ? (
      // console.log(transactionImage, "---------------transactionImage"),
      <div className="image-container">
        {transactionImage.map((url, index) => (
          url.endsWith(".pdf") ? (
            <div className="pdf-container" key={index}>
              <embed
                title={`Proof PDF ${index}`}
                src={`${url}#toolbar=0&sidebar=0`}
                width="100%"
                height="100%"
                className="proof-image"
                style={{
                  transform: `scale(${zoomFactor}) rotate(${rotationAngle}deg)`,
                  transformOrigin: `${
                    rotationAngle === 0 ? "top left" : "center center"
                  }`,
                }}
              />
            </div>
          ) : (
            <img
              key={index}
              src={url}
              alt={`Proof ${index}`}
              style={{
                transform: `scale(${zoomFactor}) rotate(${rotationAngle}deg)`,
                transformOrigin: `${
                  rotationAngle === 0 ? "top left" : "center center"
                }`,
              }}
              className="proof-image"
              onLoad={() => console.log(`Image ${index} Loaded`)}
              onError={() => console.error(`Image ${index} Error`)}
            />
          )
        ))}
      </div>
    ) : transactionImage ? (
      transactionImage.startsWith("data:application/pdf") ? (
        <div className="pdf-container">
          <embed
            title="Proof PDF"
            src={`${transactionImage}#toolbar=0&sidebar=0`}
            width="100%"
            height="100%"
            className="proof-image"
            style={{
              transform: `scale(${zoomFactor}) rotate(${rotationAngle}deg)`,
              transformOrigin: `${
                rotationAngle === 0 ? "top left" : "center center"
              }`,
            }}
          />
        </div>
      ) : (
        <div className="image-container">
          <img
            src={transactionImage}
            alt="Proof"
            style={{
              transform: `scale(${zoomFactor}) rotate(${rotationAngle}deg)`,
              transformOrigin: `${
                rotationAngle === 0 ? "top left" : "center center"
              }`,
            }}
            className="proof-image"
            onLoad={() => console.log("Image Loaded")}
            onError={() => console.error("Image Error")}
          />
        </div>
      )
    ) : (
      <span>Proof not available</span>
    )}
  </div>
</div>


        </div>
        <div className="cardcontainer">
          <div className="card">
            <div className="" >
            {/* <h1 style={{ fontSize: "20px" }}>Current Details:</h1>
            <span>Name:{rowData.customerName}</span>
            <span>EIA Number: {rowData.eiaNo}</span> */}
            {transactionType === "NEFT" || transactionType === "Neft" ? (
              <div style={{display:'flex',flexDirection:'column'}}>
              <h1 style={{fontSize:'20px'}}>Current Bank:</h1>
              <div>
             <span style={{fontWeight:'900'}}>Account No.:</span><span style={{marginLeft:'20px'}}>{rowData.currentAccountNo}</span>
             </div>
             <div>
             <span style={{fontWeight:'900'}}>ifscCode:</span><span style={{marginLeft:'20px'}}>{rowData.currentIfscCode}</span>
             </div>
             <div>
             <span style={{fontWeight:'900'}}>Bank Name:</span><span style={{marginLeft:'20px'}}>{rowData.currentBankName}</span>
             </div>
             <div>
             <span style={{fontWeight:'900'}}>Branch Name:</span><span style={{marginLeft:'20px'}}>{rowData.currentBranchName}</span>
             </div>
              </div>
            ):""}
            {(transactionType != "Neft" && transactionType != "NEFT") ? (
             <div style={{display:'flex',flexDirection:'column'}}>
             <h1 style={{fontSize:'20px'}}>Current Address:</h1>
             <div>
             <span style={{fontWeight:'900'}}>Address Line 1:</span><span style={{marginLeft:'20px'}}>{rowData.currentAddress1}</span>
             </div>
             <div>
             <span style={{fontWeight:'900'}}>Address Line 2:</span><span style={{marginLeft:'20px'}}>{rowData.currentAddress2}</span>
             </div>
             <div>
             <span style={{fontWeight:'900'}}>Address Line 3:</span><span style={{marginLeft:'20px'}}>{rowData.currentAddress3}</span>
             </div>
             <div>
             <span style={{fontWeight:'900'}}>City:</span><span style={{marginLeft:'20px'}}>{rowData.currentCity}</span>
             </div>
             <div>
             <span style={{fontWeight:'900'}}>State:</span><span style={{marginLeft:'20px'}}>{rowData.currentState}</span>
             </div><div>
             <span style={{fontWeight:'900'}}>Pincode:</span><span style={{marginLeft:'20px'}}>{rowData.currentPincode}</span>
             </div>
             </div>
            ):""}
            </div>
            <br></br>
            {(transactionType !=="NEFT" && transactionType !== "Neft") ? (
              <div className="address-container">
                <span style={{ fontWeight: "500", fontSize: "20px" }}>
                  Details fetched from the Address proof
                </span>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label>
                    Address Line 1<span style={{ color: "red" }}>*</span>
                    <input
                      type="text"
                      value={add1}
                      onChange={(e) =>
                        inputAlphaNumericWithSpl(e, (value) => {
                          setAdd1(value.slice(0, 45));
                        })
                      }
                      required
                      className="proof-input"
                    />
                  </label>
                  <label>
                    Address Line 2:
                    <input
                      type="text"
                      value={add2}
                      onChange={(e) =>
                        inputAlphaNumericWithSpl(e, (value) => {
                          setAdd2(value.slice(0, 45));
                        })
                      }
                      className="proof-input"
                    />
                  </label>
                  <label>
                    Address Line 3:
                    <input
                      type="text"
                      value={add3}
                      onChange={(e) =>
                        inputAlphaNumericWithSpl(e, (value) => {
                          setAdd3(value.slice(0, 45));
                        })
                      }
                      className="proof-input"
                    />
                  </label>
                </div>
                <div>
                  <label>
                    City<span style={{ color: "red" }}>*</span>
                    <input
                      type="text"
                      value={addCity}
                      onChange={(e) =>
                        inputAlpha(e, (value) => {
                          setAddCity(value.slice(0, 15));
                        })
                      }
                      className="proof-input"
                    />
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <label>
                    Pin Code<span style={{ color: "red" }}>*</span>
                    <input
                      type="text"
                      value={pincode}
                      onChange={(e) =>
                        inputNumeric(e, (value) => {
                          setPincode(value.slice(0, 6));
                        })
                      }
                    />
                  </label>
                  <label>
                    State<span style={{ color: "red" }}>*</span>
                  
                    <select id="country-state" name="country-state" onChange={handleState} defaultValue={toTitleCase(state)}>
            {States.map((state, index) => (
                <option key={index} value={state.value}>{state.label}</option>
            ))}
        </select>
                    
                  </label>
                </div>
              </div>
            ):""}
            
            {transactionType == "NEFT" || transactionType == "Neft" ? (
              <div className="address-container">
                <span style={{ fontWeight: "500", fontSize: "20px" }}>
                  Details fetched from the Cheuqe Leaf
                </span>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label>
                    Account Number<span style={{ color: "red" }}>*</span>
                    <input
                      type="text"
                      value={accNo}
                      onChange={(e) =>
                        inputNumeric(e, (value) => {
                          setAccNo(value.slice(0, 20));
                        })
                      }
                    />
                  </label>
                  <label>
                    IFSC code<span style={{ color: "red" }}>*</span>
                    <input
                      type="text"
                      value={ifscCode}
                      onChange={(e) =>
                        inputAlphaNumeri(e, (value) => {
                          setIfscCode(value.slice(0, 13));
                        })
                      }
                    />
                  </label>
                  <label>
                    Bank Name<span style={{ color: "red" }}>*</span>
                    <input
                      type="text"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                      required
                      readOnly
                    />
                  </label>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <label>
                    Branch Name<span style={{ color: "red" }}>*</span>
                    <input
                      className="input"
                      type="text"
                      value={branchName}
                      onChange={(e) => setBranchName(e.target.value)}
                      required
                      readOnly
                    />
                  </label>
                </div>
              </div>
            ):""}

            
            {status == "REJECTED" && status != "APPROVED" && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <a>
                  Manual Status:
                  <span className="status-reject">{status}</span>
                </a>
                <a>Reject Reason:<span className="status-reject">{rowData.rejectReason}</span></a>
                <a>Reject Comments:<span className="status-reject">{rowData.rejectComments}</span></a>
              </div>
            )}
            {status != "REJECTED" && status == "APPROVED" && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <a>
                  Manual Status:
                  <span className="status-approve">{status}</span>
                </a>
              </div>
            )}
          </div>
          {status !== "REJECTED" && status !== "APPROVED" && (
              <div className="check-box">
                <div>
                  <input
                    type="radio"
                    value="approve"
                    checked={approvalStatus === "approve"}
                    onChange={handleApprovalChange}
                    style={{ color: "green" }}
                  />
                  Approve
                </div>
                <div>
                  <input
                    type="radio"
                    value="reject"
                    checked={approvalStatus === "reject"}
                    onChange={handleApprovalChange}
                    style={{ color: "red" }}
                  />
                  Reject
                </div>
              </div>
            )}
              {approvalStatus === "reject" && (
                        <div className="reject-reason">
                          <div>
                            <span>Select Reject Reason</span>
                          <select
                            value={rejectReason}
                            onChange={handleRejectReasonChange}
                          >
                            <option value="">Reject Reason</option>
                            <option value="proof Not valid">
                              proof Not Clear
                            </option>
                            <option value="Name missmatch with our Record">
                              Name missmatch with our record
                            </option>
                            <option value="Name given and the name on proof mismatch">
                              Name given and the name on proof mismatch
                            </option>
                            <option value="Submitted proof not valid">
                              Submitted proof not valid
                            </option>
                            <option value="other">other</option>
                          </select>
                          </div>
                          {rejectReason === "other" && (
                            <div>
                              <label>
                                Reject Reason:
                                <input
                                  type="text"
                                  value={rejectComments}
                                  onChange={handleRejectCommentsChange}
                                />
                              </label>
                            </div>
                          )}
                        </div>
                      )}
        </div>
        <div>

        </div>
      </div>
    </div>
  );
};
