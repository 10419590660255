import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import Navbar from "../Header/Navbar";
import {
  ROUTER_URL,
  LABEL_NAME,
  exportAsExcel,
  SERVICE_TYPE,
  POLICY_TYPE,
} from "../Common/constant";
import dayjs from 'dayjs';
import Select from "react-select";
import ActionButton from "../Common/ActionButton";
import { useNavigate } from "react-router-dom";
import { RotateSpinner } from "react-spinners-kit";
import { v4 as uuid } from "uuid";
import * as XLSX from "xlsx";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "./style.css";
import { v4 as uuidv4 } from 'uuid';
import BasicTable from "../Common/TableInsa";
import { array } from "../POCList/Tables";






export default function InSaMatch() {



  const [EiaNo, setEiaNo] = useState("");
  const [policyNo, setPolicyNo] = useState("");
  const [policyType, setPolicyType] = useState("ALL");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [loading, setLoading] = useState(false);
  // const [loadingview, setLoadingview] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [transactionStatus, setTransactionStatus] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [showTableview, setShowTableview] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [responseViewData, setResponseViewData] = useState([]);
  const [insaValue, setInsaValue] = useState([])
  const [insunetValue, setInsunetValue] = useState([])

  const [policyTypeForresponse, setPolicyTypeForresponse] = useState("H");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };



  let myuuid = uuidv4();

  const INSURER_COLUMNS = [
    { id: "Sl_No", label: "Sno", minWidth: 50 },
    { id: "eiaNo", label: "eIA number", minWidth: 100 },
    { id: "policyNo", label: "Policy number", minWidth: 100 },
    { id: "policyType", label: "Policy type", minWidth: 100 },
    { id: "fileName", label: "File name", minWidth: 100 },
    { id: "insCompanyCd", label: "InSaCompany Code", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
  ];
  
  const columns = INSURER_COLUMNS;

  const todayDate = new Date().toISOString().split("T")[0];

  const navigate = useNavigate();

  const clickExport = () => {
    exportAsExcel("POCList.xlsx", responseData);
  };

  const resetClick = (e) => {
    e.preventDefault();
    setShowTable(false)
    setShowTableview(false)
    setEiaNo("");
    setFromDate("");
    setToDate("");
    setPolicyNo("");
    setPolicyType("");
    setTransactionStatus("");
  };

  const backbutton = () => {
    navigate("/menudisplaypage");
  };

  const inputNumeric = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9]+$/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const inputAlphaNumericWithSpl = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z-_/\\]+$/gm, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const handleButtonClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    setShowTableview(false);
    // const formattedFromDate = formatDate(fromDate);

    // const formattedToDate = formatDate(toDate);
    if (!fromDate || !toDate) {
      alert("Please fill in all mandatory fields");
      setLoading(false)

      return;
    }


    // const formattedFromDate = new Date(fromDate).toLocaleDateString("en-GB"); // Assumes 'toDate' is in the format "YYYY-MM-DD"

    // const formattedToDate = new Date(toDate).toLocaleDateString("en-GB"); // Assumes 'toDate' is in the format "YYYY-MM-DD"
    const formattedFromDate = dayjs(fromDate, 'YYYY-MM-DD').format('DD-MM-YYYY')

    const formattedToDate = dayjs(toDate, 'YYYY-MM-DD').format('DD-MM-YYYY')
    // const BaseUrl = "https://sitcamsrep.bimacentral.in"
    // const EndPoint = "/riInsurerBulkService/v1/ListPolicyAPI";

    const BaseUrl = process.env.REACT_APP_API_URL;

    const UrlInsaMatch = "/riInsurerBulkService/v1/getInsaLogDetails";

    try {
      const response = await fetch(`${BaseUrl}${UrlInsaMatch}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          txnId: "{{$guid}}",
          timestamp: "2023-07-31T19:43:25.387994200",
          Source: {
            appType: "MOBILE",
            osVersion: "11",
            deviceId: "",
            deviceName: "Mymobile 52",
            deviceOS: "Android",
            appVersion: "2.0",
            clientIp: "0.0.0.0",
            origin: ""
          },
          Customer: {
            fromDate: formattedFromDate,
            toDate: formattedToDate,
            pageNumber: "1",
            pageSize: "15"
          }
        }
        ),
      });

      if (response.ok) {
        setLoading(false);
        let jsonResponse = await response.json();
        if (jsonResponse.errorDescription === "No data found") {
          // setLoading(false)
          alert(jsonResponse.errorDescription);
        }
        let res = jsonResponse.insaAdminResp;

        // for (let i = 0; i < res.length; i++){
        //   res[0].status = "fail";
        // }



        let convertedData = [];
        res.forEach((element, index) => {
          let temp = {};
          // temp["Sl No"] = "0";
          columns.forEach((column) => {
            if (column.id === "Sl_No") {
              temp[column.label] = index + 1;
            } else {
              temp[column.label] = element[column.id];
            }
          });
          convertedData.push(temp);
          temp = {};
        });

        setResponseData(convertedData);
        setShowTable(true);
      } else {
        const errorResponse = await response.json();
        setLoading(false);
        setShowTable(false);
        alert(`Error: ${errorResponse.errorDescription}`);
      }
    } catch (error) {
      setLoading(false);
      setShowTable(false);
      // Handle network errors or any other exceptions
      console.error("Error:", error.message);
    }
  };





  function downloadTextFile(textContent, fileName) {
    const blob = new Blob([textContent], { type: "text/plain" });
    const url = URL.createObjectURL(blob);

    let a =
      document.getElementById("download-link") || document.createElement("a");
    a.id = "download-link";
    a.href = url;
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(url);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleExportClick = () => {
    // Export response data as Excel
    exportAsExcel(responseData);
    // console.log("Export Success");
  };

  const exportAsExcel = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `InSa Match report From ${fromDate} to ${toDate}.xlsx`);
  };



  const handleClickPlain = async (item) => {
    // e.preventDefault();
   

    const eiaNum = item["eIA number"]
    const policyNum = item["Policy number"]
    const polyTy = item["Policy type"]
    const InSaCompanyCode = item["InSaCompany Code"]

    setPolicyTypeForresponse(polyTy)

    sessionStorage.setItem("eiaNo", JSON.stringify(eiaNum));
    sessionStorage.setItem("policyNo", JSON.stringify(policyNum));
    sessionStorage.setItem("companyCode", JSON.stringify(InSaCompanyCode));
    sessionStorage.setItem("policyTy", JSON.stringify(polyTy));

    setLoading(true);

    const BaseUrl = process.env.REACT_APP_API_URL;

    const UrlViewInsaMatch = "/riInsurerBulkService/v1/viewInsaPolicyDetails";

    try {
      const response = await fetch(`${BaseUrl}${UrlViewInsaMatch}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          txnId: myuuid,
          timestamp: "2023-07-31T19:43:25.387994210",
          Source: {
            appType: "MOBILE",
            osVersion: "11",
            deviceId: "",
            deviceName: "Mymobile 52",
            deviceOS: "Android",
            appVersion: "2.0",
            clientIp: "0.0.0.0",
            origin: ""
          },
          Customer: {
            eiaNo: eiaNum,
            policyNo: policyNum,
            policyType: polyTy,
            insuranceCompanyCode: InSaCompanyCode
          }
        }
        ),
      });

      if (response.ok) {
        setLoading(false);
        setShowTable(false)

        setShowTableview(true)
        let jsonResponse = await response.json();
        let res = jsonResponse?.data;

        setResponseViewData(res);
        // setShowTableview(true)
      } else {
        const errorResponse = await response.json();
        setLoading(false);
        setShowTable(false);
        alert(`Error: ${errorResponse.errorDescription}`);
      }
    } catch (error) {
      setLoading(false);
      setShowTable(false);
      // Handle network errors or any other exceptions
      console.error("Error:", error.message);
    }




  }

  const StatusResult = {}

  let typrValue1 = policyTypeForresponse === "H" ? responseViewData?.insaHealthPolicyDetails : responseViewData?.insaLifePolicyDetails
  let typrValue2 = policyTypeForresponse === "H" ? responseViewData?.insunetHealthPolicyDetails : responseViewData?.insunetLifePolicyDetails

  const data1 = typrValue1
  const data2 = typrValue2
  // console.log(data1,data2,"data1data1data1data1")
  const newArrayForView = []
  // console.log(data1,"data1data1data1data1")
  for (let item in data1) {
    newArrayForView.push(item)
  }



  if (data1) {
    for (let key in data1) {
      if (data1[key] === data2[key]) {
        StatusResult[key] = "Same"
      } else {
        StatusResult[key] = "Not Same"
      }
    }
  }

  // console.log(StatusResult,"StatusResultStatusResult")


  return (
    <div>
      <Header />
      <div className="MenuPage">
        <Navbar pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
      </div>
      <div className="app-insurer-insa-match">
        <div>
          <h1 className="heading">
            {ROUTER_URL.INSA_MATCH}
          </h1>
        </div>
        <div className="insa-card">
          <div className="row col-12">
            <div className="col-12">
              <form method="post insa-match">
                <div class="row">
                  <div className="row col-12">
                    <div className="col-xs-12 col-sm-4 username">
                      <label className="label_cls">{LABEL_NAME.FROM_DATE}</label>
                      <div class="">
                        <input
                          type="date"
                          name="fromDate"
                          value={fromDate}
                          style={{ marginTop: "0px" }}
                          className="inputboxvu_insure form-control"
                          max={todayDate}
                          onChange={(e) => setFromDate(e.target.value)}
                        // required
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 username">
                      <label className="label_cls">{LABEL_NAME.TO_DATE}</label>
                      <div class="">
                        <input
                          type="date"
                          name="toDate"
                          style={{ marginTop: "0px" }}
                          value={toDate}
                          className="inputboxvu_insure form-control"
                          max={todayDate}
                          onChange={(e) => setToDate(e.target.value)}
                        // required
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 username-btn mb-2">

                      <ActionButton
                        handleBack={backbutton}
                        handleReset={resetClick}
                        handleSubmit={handleButtonClick}
                      />


                    </div>
                  </div>
                </div>
                {/* <p style={{color:'#f99117'}}>**Note: POC status will be available from the date of July 11, 2024 and afterwards.</p> */}

              </form>
              <div className="loading">
                <RotateSpinner size={70} color="#f99117" loading={loading} />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="item-insurer">
              <div className="tableContainer-insurer">
                {showTable && (
                  <div className="">
                    <button
                      onClick={handleExportClick}
                      className="export-button"
                    >
                      Export as Excel
                    </button>
                    {/* <Button variant="outlined">Outlined</Button> */}
                    <div class="">
                      <Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                {columns.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {responseData
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                  
                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={row.code}
                                    >
                                      {columns.map((column) => {
                                        const value = row[column.label];
    
                                        return (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                          >
                                            {value}
                                            {column.id === "status" && value === "Success" && (row["eIA number"] !== "NA" && row["Policy number"] !== "NA") ? (
                                              <Button
                                                variant="contained"
                                                className="request-button ml-2"
                                                onClick={() =>
                                                  handleClickPlain(
                                                    row
                                                  )
                                                }
                                              >
                                                View Policy Details
                                              </Button>
                                            ) : (
                                              ""
                                            )}
                                          </TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, 100]}
                          component="div"
                          count={responseData.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

        </div>

      </div>
      {showTableview ? <BasicTable viewData={responseViewData} setShowTableview={() => setShowTableview(false)} /> : ""}
    </div>
  )
}
